.toast{
    position: fixed;
    top: 20px;
    left: -500px;
    background-color: white;
    border: 1px solid var(--success-bg);
    border-left: 2rem solid var(--success-bg);
    padding: 2em;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: .5rem;
    backdrop-filter: blur(5px);
    border-radius: 0.5rem;
    transition: transform .5s ease-in-out;
    transform: translateX(-100%);
    filter: drop-shadow(5px 5px 4px var(--text));
  }
  .toast-fail{
    position: fixed;
    top: 20px;
    left: -500px;
    background-color: white;
    border: 1px solid var(--error-bg);
    border-left: 2rem solid var(--error-bg);
    padding: 2em;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: .5rem;
    backdrop-filter: blur(5px);
    border-radius: 0.5rem;
    transition: transform .5s ease-in-out;
    transform: translateX(-100%);
    filter: drop-shadow(5px 5px 4px var(--text));
  }

.toast.show {
    transform: translateX(525px); /* On-screen */
}

.toast.hide {
    transform: translateX(-500px); /* Off-screen to the left */
}

.toast-fail.show {
  transform: translateX(525px); /* On-screen */
}

.toast-fail.hide {
  transform: translateX(-500px); /* Off-screen to the left */
}
  .modal-content {
    display: flex;
    flex-direction: column;
  }
  .fail-modal {
      position: fixed;
      /* bottom: 2rem;
      left: 1rem; */
      margin: 0 auto;
      background-color: var(--error-bg);
      color: var(--error-text);
      /* width: 300px; */
      padding: 2em;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: .5rem;
      backdrop-filter: blur(5px);
      border-radius: 0.5rem;
  }
  
  .modal-header {
    display: flex;
  }
  .modal-title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .modal-text {
    font-size: 1rem;
  }
  
  .check-div {
    max-width: 3.125rem;
    display: flex;
    align-items: center;
    & img {
      height: 100%;
      width: 100%;
    }
  }
  