.flyer {
  max-width: 30rem;
  align-self: center;
  & img {
    height: 100%;
    width: 100%;
  }
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
  text-align: left;
  width: 100%;
}

.detail {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.75rem;
  padding: 0.5rem 0.25rem;
  border-bottom: 1px solid var(--secondary);
  width: 100%;
}

.detail-text {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.event-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.more-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 1.5rem;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.host-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.header {
  background-color: white;
}
.logo-div {
  max-width: 150px;
  & img {
    width: 100%;
    height: 100%;
  }
}

._2ILZE._3q7r8 {
  background-color: var(--secondary);
}

.section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.self-exam {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-top: 0.25px solid var(--primary);
}
.exam-instructions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.instruction {
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
}

.num-instruction {
  background-color: var(--primary);
  padding: 1rem;
  color: white;
}

.instruction-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.sticky-div {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.5rem;
  filter: drop-shadow(0px 1px 8px var(--primary));
  z-index: 999;
  background-color: white;
  @media screen and (min-width: 768px) {
    width: 400px;
  }
}

.btn-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}
.qty-btn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem;
}

.purchaser-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(253, 103, 160, 0.7);
  z-index: 998;
}

.btn {
  border: none;
  background: none;
}

.error {
  color: var(--error-bg);
  font-weight: bold;
}

.purchase-btn-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.vip-guest-inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  border-top: 1px solid var(--primary);
  padding: 1rem;
}

.guest-list {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: .5rem;
  max-height: 300px;
  overflow-y: auto;
  
}

.guest-listing {
  display: flex;
  gap: .5rem;
  padding: .5rem;
  /* border: 1px solid var(--secondary); */
  align-items: center;
  flex-direction: space-around;
  width: 100%;

}

.vip-num {
  padding: 1rem;
  /* background-color: var(--primary); */
  border: 1px solid var(--primary);
  color: var(--primary);
}

.edit-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.personal-info {
  display: flex; 
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
}

.input-div {
  display: flex; 
  flex-direction: column;
  gap: .5rem;
  width: 100%;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.guest-info {
  display: flex; 
  flex-direction: column;

}

.support-div {
  padding: 1rem;
  text-align: center;
}