.confirmation-message {
   text-align: left;
   display: flex;
   flex-direction: column;
   gap: 1rem;
   border-bottom: .5px solid var(--primary);
   padding: 1rem;
}

.reservation-details {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
}

.r-details {
    display: flex;
    flex-direction: column;

}