.circle-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  
}

.loadingCircle {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--secondary);
  border-radius: 50%;
}