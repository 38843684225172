.header {
    display: flex;
    flex-direction: column;

}

.barcodes {
    /* width: 300px; */
    align-self: center;
    display: flex;
    flex-direction: column;

}

.page-content-2 {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: center;
}

.checked-in-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    background-color: var(--secondary);
    padding: 1rem;
    border-left: 10px solid var(--success-bg);
}
.void-img {

    position: fixed;
   top: 0;
}