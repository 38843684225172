.barcodes-carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nav-btn-grp {
    display: flex;
    align-items: center;
    justify-content: center;
}