:root {
  --primary: #fd67a0;
  --secondary: #FFD2E3;
  --text: #797979;
  --success-bg: rgba(20, 255, 0, 0.70);
  --success-text: #0A5F02;
  --error-bg: rgba(255, 40, 40, .57);
  --error-text: #3B0101;
}
.App {
  text-align: center;
  color: var(--text);
  text-align: left;
  background-color: var(--secondary);
  background-image: url('/src/Resources/ribbon.jpeg');
  object-fit: cover;
}

.h1 {
  font-size: 1.5rem;
  color: var(--primary);
  font-weight: bold;
}

.page-view {
  /* height: 100vh; */
}
.h1-overline {
  color: var(--primary);
}

.page-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 42rem;
  background-color: white;
  height: 100vh;
  overflow-y: auto;
}

.h4 {
  font-size: 20px;
  font-weight: 600;
}

.h6 {
  font-size: 1.125rem;
  font-weight: 500;
}

a {
  color: var(--primary);
  font-weight: bold;
  font-size: 1.125rem;
  text-decoration: none;
}

.a-small {
  font-size: 12px;
}
.caption {
  align-self: center;
  font-size: .75rem;
}
