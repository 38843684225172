.search {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  border-bottom: 1px solid var(--secondary);
  padding: 2rem 1rem;
}

.purchaser-tile {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  padding: .5rem;
}

.purchasers-list {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  gap: .5rem;
}

.success-modal {
  position: fixed;
  /* bottom: 2rem;
  left: 1rem; */
  background-color: var(--success-bg);
  color: var(--success-text);
  /* width: 300px; */
  padding: 2em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: .5rem;
  backdrop-filter: blur(5px);
  border-radius: 0.5rem;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.fail-modal {
    position: fixed;
    /* bottom: 2rem;
    left: 1rem; */
    margin: 0 auto;
    background-color: var(--error-bg);
    color: var(--error-text);
    /* width: 300px; */
    padding: 2em;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: .5rem;
    backdrop-filter: blur(5px);
    border-radius: 0.5rem;
}

.modal-header {
  display: flex;
}
.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.modal-text {
  font-size: 1.25rem;
}

.check-div {
  max-width: 3.125rem;
  display: flex;
  align-items: center;
  & img {
    height: 100%;
    width: 100%;
  }
}

.purchase-details {
  display: flex;
  flex-direction: column;

}

.caption-left {
  font-size: .875rem;
}

.check-in-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.vip-list {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-left: 3px solid var(--primary);
}

.vips {
  padding: 0rem 3rem;
}